// import { testimonials } from "/js/testimonials";
// import { typer } from "/js/typer";
import { topheight } from "/js/topheight";
import { tabs } from "/js/tabs";
//import { table } from "/js/table";
var ScrollMagic = require('scrollmagic');

var tabsInstance = new tabs;

// testimonials
var topheightInstance = new topheight;
// testimonialsInstance.setSliderHolderWidth();

// Window Resize
var windowWidth = window.innerWidth;
window.onresize = function(){
  // testimonials
  topheightInstance.setHeight();
};

var controller = new ScrollMagic.Controller();

if(windowWidth > 770){
  // create a scene
  const scende = new ScrollMagic.Scene({
    duration: 500, // the scene should last for a scroll distance of 100px
    offset: 0 // start this scene after scrolling for 50px
  });
  scende
    .setPin('#head') // pins the element for the the scene's duration
    .addTo(controller) // assign the scene to the controller
    .setClassToggle("#person", "thinking");

    function callback (event) {
      console.log("Event fired! (" + event.type + ")");
      scende.removeClassToggle();
  }
  // add listeners
  scende.on("leave", callback);

  
}