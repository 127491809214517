class tabs{

  constructor() {
    // this.squaresize = 50;
    // this.colors = ['#ff6d00', '#048174', '#f0dc00', '#c7d300', '#b9e0de', '#5c004e'];
    // this.squares = this.numberOfSquares(this.squaresize);
    this.tabAction();
  }

  tabAction(){
    const tabContainer = document.getElementsByClassName('tabs');
    const tablinks = tabContainer[0].getElementsByClassName('tablink');
    const tabs = tabContainer[0].getElementsByClassName('tab');
    Array.prototype.forEach.call(tablinks, function (value, index) {
      value.addEventListener('click', function (e) {
        tabs[0].style.display = 'none';
        tabs[1].style.display = 'none';
        tabs[index].style.display = 'block';
        tablinks[0].classList.remove('active');
        tablinks[1].classList.remove('active');
        tablinks[index].classList.add('active');
        return false;
        // e.preventDefault();
      });
    });
  }

}

export {tabs};
